import React from "react";

export default function Logo() {
  return (
    <svg
      width="62"
      height="21"
      viewBox="0 0 62 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.985352 18.166V20.6782H61.896V18.166H0.985352Z"
        fill="url(#paint0_linear_2_495)"
      />
      <path
        d="M3.82859 16.3935V7.54791H0.985352V5.20112H3.82859V3.1251C3.82859 2.35788 4.05424 1.74861 4.50555 1.29731C4.9719 0.830954 5.57364 0.597778 6.31078 0.597778H9.24428V2.94458H7.30366C6.88244 2.94458 6.67183 3.17023 6.67183 3.62154V5.20112H9.60533V7.54791H6.67183V16.3935H3.82859Z"
        className=" dark:fill-white fill-black"
      />
      <path
        d="M15.945 16.7095C15.0423 16.7095 14.2074 16.4913 13.4402 16.0551C12.673 15.6038 12.0562 14.9494 11.5898 14.0919C11.1235 13.2344 10.8903 12.1964 10.8903 10.9779V10.6168C10.8903 9.39827 11.1235 8.36027 11.5898 7.50278C12.0562 6.6453 12.673 5.99842 13.4402 5.56216C14.2225 5.11085 15.0574 4.8852 15.945 4.8852C16.9679 4.8852 17.7427 5.0582 18.2692 5.4042C18.8108 5.73516 19.2094 6.11877 19.4652 6.55504H19.8713V5.20112H22.6694V13.3698C22.6694 13.8211 22.8801 14.0467 23.3013 14.0467H24.2039V16.3935H21.9473C21.4058 16.3935 20.9545 16.2431 20.5934 15.9422C20.2474 15.6414 20.0744 15.2277 20.0744 14.7011V14.6786H19.6682C19.4125 15.2352 18.9988 15.7166 18.4272 16.1228C17.8705 16.5139 17.0431 16.7095 15.945 16.7095ZM16.8024 14.2273C17.69 14.2273 18.4196 13.949 18.9913 13.3923C19.578 12.8207 19.8713 11.9933 19.8713 10.9102V10.6845C19.8713 9.60136 19.578 8.78149 18.9913 8.22487C18.4046 7.65322 17.675 7.36739 16.8024 7.36739C15.9299 7.36739 15.2003 7.65322 14.6136 8.22487C14.0269 8.78149 13.7336 9.60136 13.7336 10.6845V10.9102C13.7336 11.9933 14.0269 12.8207 14.6136 13.3923C15.2003 13.949 15.9299 14.2273 16.8024 14.2273Z"
        className=" dark:fill-white fill-black"
      />
      <path
        d="M26.3286 16.3935V5.20112H29.1718V16.3935H26.3286Z"
        className=" dark:fill-white fill-black"
      />
      <path
        d="M36.971 16.7095C35.5117 16.7095 34.3158 16.3935 33.3831 15.7617C32.4504 15.1299 31.8862 14.2273 31.6907 13.0539L34.3082 12.3769C34.4135 12.9034 34.5865 13.3171 34.8272 13.618C35.083 13.9189 35.3914 14.137 35.7524 14.2724C36.1285 14.3927 36.5347 14.4529 36.971 14.4529C37.6329 14.4529 38.1218 14.3401 38.4377 14.1144C38.7536 13.8737 38.9116 13.5804 38.9116 13.2344C38.9116 12.8884 38.7611 12.6251 38.4603 12.4446C38.1594 12.249 37.678 12.0911 37.0161 11.9707L36.3843 11.8579C35.602 11.7075 34.8874 11.5044 34.2405 11.2486C33.5937 10.9779 33.0747 10.6093 32.6835 10.1429C32.2924 9.67658 32.0968 9.07484 32.0968 8.3377C32.0968 7.22448 32.503 6.37451 33.3154 5.78781C34.1277 5.18607 35.1958 4.8852 36.5196 4.8852C37.7683 4.8852 38.8063 5.16351 39.6337 5.72012C40.4611 6.27673 41.0026 7.00635 41.2584 7.90896L38.6182 8.72131C38.4979 8.14966 38.2497 7.74348 37.8736 7.50278C37.5125 7.26209 37.0612 7.14174 36.5196 7.14174C35.9781 7.14174 35.5644 7.23952 35.2786 7.43509C34.9927 7.61561 34.8498 7.87135 34.8498 8.20231C34.8498 8.56336 35.0002 8.83414 35.3011 9.01466C35.602 9.18014 36.0082 9.30801 36.5196 9.39827L37.1515 9.5111C37.9939 9.66154 38.7536 9.86462 39.4306 10.1204C40.1226 10.3611 40.6642 10.7146 41.0553 11.1809C41.4615 11.6322 41.6646 12.249 41.6646 13.0313C41.6646 14.2047 41.2358 15.1148 40.3783 15.7617C39.5359 16.3935 38.4001 16.7095 36.971 16.7095Z"
        className=" dark:fill-white fill-black"
      />
      <path
        d="M48.6692 16.7095C47.7665 16.7095 46.9316 16.4913 46.1644 16.0551C45.3972 15.6038 44.7804 14.9494 44.314 14.0919C43.8477 13.2344 43.6145 12.1964 43.6145 10.9779V10.6168C43.6145 9.39827 43.8477 8.36027 44.314 7.50278C44.7804 6.6453 45.3972 5.99842 46.1644 5.56216C46.9467 5.11085 47.7816 4.8852 48.6692 4.8852C49.6921 4.8852 50.4669 5.0582 50.9934 5.4042C51.535 5.73516 51.9336 6.11877 52.1894 6.55504H52.5955V5.20112H55.3936V13.3698C55.3936 13.8211 55.6043 14.0467 56.0255 14.0467H56.9281V16.3935H54.6715C54.13 16.3935 53.6787 16.2431 53.3176 15.9422C52.9716 15.6414 52.7986 15.2277 52.7986 14.7011V14.6786H52.3924C52.1367 15.2352 51.723 15.7166 51.1514 16.1228C50.5947 16.5139 49.7673 16.7095 48.6692 16.7095ZM49.5266 14.2273C50.4142 14.2273 51.1438 13.949 51.7155 13.3923C52.3022 12.8207 52.5955 11.9933 52.5955 10.9102V10.6845C52.5955 9.60136 52.3022 8.78149 51.7155 8.22487C51.1288 7.65322 50.3992 7.36739 49.5266 7.36739C48.6541 7.36739 47.9245 7.65322 47.3378 8.22487C46.7511 8.78149 46.4578 9.60136 46.4578 10.6845V10.9102C46.4578 11.9933 46.7511 12.8207 47.3378 13.3923C47.9245 13.949 48.6541 14.2273 49.5266 14.2273Z"
        className=" dark:fill-white fill-black"
      />
      <path
        d="M59.0528 16.3935V0.597778H61.896V16.3935H59.0528Z"
        className=" dark:fill-white fill-black"
      />
      <path
        d="M26.4414 3.39588C26.8024 3.72684 27.2387 3.89232 27.7502 3.89232C28.2767 3.89232 28.7129 3.72684 29.059 3.39588C29.405 3.06492 29.578 2.62866 29.578 2.08709C29.578 1.54552 29.405 1.10926 29.059 0.778299C28.7129 0.44734 28.2767 0.28186 27.7502 0.28186C27.2387 0.28186 26.8024 0.44734 26.4414 0.778299C26.0954 1.10926 25.9224 1.54552 25.9224 2.08709C25.9224 2.62866 26.0954 3.06492 26.4414 3.39588Z"
        fill="url(#paint1_linear_2_495)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_495"
          x1="-0.867654"
          y1="17.7667"
          x2="62.0083"
          y2="22.2904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#309AFC" />
          <stop offset="0.333333" stop-color="#5754FF" />
          <stop offset="0.666667" stop-color="#FF51A4" />
          <stop offset="1" stop-color="#FF7C51" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_495"
          x1="25.8112"
          y1="-0.292038"
          x2="29.6042"
          y2="-0.280641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#309AFC" />
          <stop offset="0.333333" stop-color="#5754FF" />
          <stop offset="0.666667" stop-color="#FF51A4" />
          <stop offset="1" stop-color="#FF7C51" />
        </linearGradient>
      </defs>
    </svg>
  );
}
