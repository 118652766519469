import React, { useEffect, useState } from "react";
// import { ThemeToggler } from "gatsby-plugin-dark-mode";
import Sun from "./Sun";
import Moon from "./Moon";

export default function ThemeToggle(props) {
  const [isDarkMode, setIsDarkMode] = useState(true)
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);

  };
  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      document.querySelector("html").classList.add("dark");
    } else {
      document.querySelector("html").classList.remove("dark");
    }
    setIsDarkMode(document.querySelector("html").classList.contains("dark"));
    props.onChangeDarkMode(isDarkMode);
  }, []);
  useEffect(() => {
    if (isDarkMode) {
      document.querySelector("html").classList.add("dark");
    } else {
      document.querySelector("html").classList.remove("dark");
    }
    props.onChangeDarkMode(isDarkMode);

  }, [isDarkMode]);
  return (
    <>
      <button
        className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-800 flex items-center justify-center"
        onClick={toggleTheme}
      >
        {isDarkMode ? <Moon /> : <Sun />}
      </button>
    </>
  );
}
